import React, { useState } from 'react';
import ProfessionalTitle from '../../core/components/ProfessionalTitle';
import { Box, Typography } from '@mui/material';
import theme from '../../core/lib/theme';

const MortgageCalculator = () => {
  const [capitalPendiente, setCapitalPendiente] = useState(0);
  const [plazoAmortizacion, setPlazoAmortizacion] = useState(0); // En años
  const [interes, setInteres] = useState(0);
  const [cuotaMensual, setCuotaMensual] = useState(0);
  const MESES_EN_ANO = 12;

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'left'
    },
    formContainer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(3),
      borderRadius: theme.shape.borderRadius,
      boxShadow: 3,
      maxWidth: '50%',
      margin: '0 auto'
    },
    formGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2)
    },
    formLabel: {
      fontWeight: 'bold',
      color: '#4a5568',
      marginRight: theme.spacing(2),
      flex: '1'
    },
    formInput: {
      width: '60%',
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      border: '1px solid #cbd5e0',
      borderRadius: theme.shape.borderRadius,
      fontSize: '16px',
      color: '#4a5568'
    },
    resultLabel: {
      fontSize: '1.5rem',
      fontWeight: 'bold'
    },
    resultNumber: {
      fontSize: '1.2rem'
    }
  };

  const calcularCuotaMensual = (
    capitalPendiente,
    interes,
    plazoAmortizacion
  ) => {
    const interestBy100 = interes / 100;
    // Ejemplo: 147000×((((1+0.029÷12)^(30×12))×(0.029÷12))÷((1+0.029÷12)^(30×12)−1))
    const cuota =
      capitalPendiente *
        (((1 + interestBy100 / MESES_EN_ANO) **
          (plazoAmortizacion * MESES_EN_ANO) *
          (interestBy100 / MESES_EN_ANO)) /
          ((1 + interestBy100 / MESES_EN_ANO) **
            (plazoAmortizacion * MESES_EN_ANO) -
            1)) || 0;
    return cuota.toFixed(2);
  };

  return (
    <Box>
      <ProfessionalTitle title="Calculadora de hipoteca" />
      <Box sx={styles.container}>
        <Box sx={styles.formContainer}>
          <Box sx={styles.formGroup}>
            <label style={styles.formLabel}>
              Capital pendiente a amortizar o cantidad a hipotecar
            </label>
            <input
              id="capitalPendiente"
              type="number"
              style={styles.formInput}
              value={capitalPendiente}
              onChange={(e) => setCapitalPendiente(Number(e.target.value))}
            />
          </Box>
          <Box sx={styles.formGroup}>
            <label style={styles.formLabel}>Plazo en años</label>
            <input
              id="plazoAmortizacion"
              type="number"
              style={styles.formInput}
              value={plazoAmortizacion}
              onChange={(e) => setPlazoAmortizacion(Number(e.target.value))}
            />
          </Box>
          <Box sx={styles.formGroup}>
            <label style={styles.formLabel}>Interés anual</label>
            <input
              id="interes"
              type="number"
              style={styles.formInput}
              value={interes}
              onChange={(e) => setInteres(Number(e.target.value))}
            />
          </Box>

          <Box>
            <Typography variant="h6" style={styles.resultLabel}>
              Cuota mensual
            </Typography>
            <Typography variant="h5" style={styles.resultNumber}>
              {calcularCuotaMensual(
                capitalPendiente,
                interes,
                plazoAmortizacion
              )}
              €
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MortgageCalculator;
