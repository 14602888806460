import React, { useContext } from 'react';
import theme from '../../core/lib/theme';
import ProfessionalTitle, {
  ThemeType
} from '../../core/components/ProfessionalTitle';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import {
  AddCircle as AddCircleIcon,
  Save as SaveIcon,
  DateRange as DateRageIcon,
  Today as TodayIcon
} from '@mui/icons-material';
import { RootStoreContext } from '../../core/states/RootStore';
import {
  RecurringExpense,
  RecurringExpenseExtended
} from '../states/RecurringExpensesStore';
import ResponsiveTable from '../../core/components/ResponsiveTable';
import { Periodicity } from '@prisma/client';
import { DatePicker } from '@mui/x-date-pickers';
import { observer } from 'mobx-react';
import { exportToExcell } from '../../core/lib/export';
import { runInAction } from 'mobx';

const RecurringExpenses = observer(() => {
  const storeContext = useContext(RootStoreContext);
  const store = storeContext.recurringExpensesStore;
  const [recurringExpenses, setRecurringExpenses] = React.useState<
    RecurringExpense[]
  >([]);

  const styles = {
    container: {
      margin: theme.spacing(2)
    },
    buttonsMargin: {
      margin: `${theme.spacing(1)} 0`
    },
    exportContainer: {
      // Align to the right
      display: 'flex',
      justifyContent: 'flex-end'
    },
    alert: {
      margin: `${theme.spacing(1)} 0`
    }
  };

  React.useEffect(() => {
    const loadExpenses = async () => {
      await store.loadRecurringExpenses();
      setRecurringExpenses(store.recurringExpenses);
    };
    loadExpenses();
  }, []);

  const handleCreateDailyExpense = () => {
    store.createRecurringExpense();
  };

  const currentMonthShort = new Date().toLocaleDateString('default', {
    month: 'short'
  });
  const nextMonthShort = new Date(
    new Date().setMonth(new Date().getMonth() + 1)
  ).toLocaleDateString('default', {
    month: 'short'
  });
  const currentYear = new Date().getFullYear();
  // Get the year of the next month
  const nextMonthYear = new Date(
    new Date().setMonth(new Date().getMonth() + 1)
  ).getFullYear();

  const anyNominaInRecurringExpenses = recurringExpenses
    .filter((d) => !d.isExpense)
    .filter((d) => !d.toDelete)
    .some((d) => d.category.name === 'Nomina');

  return (
    <Box>
      <ProfessionalTitle title="Gastos/Ingresos recurrentes" />
      <Box sx={styles.container}>
        {recurringExpenses.length !== 0 && (
          <Box sx={styles.exportContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                exportToExcell({
                  data: store.toJsonCSV,
                  fileName: 'GastosRecurrentes.xls'
                });
              }}
              startIcon={<AddCircleIcon />}
              sx={styles.buttonsMargin}
            >
              Exportar
            </Button>
          </Box>
        )}
        <ResponsiveTable
          isLoading={store.isLoading}
          data={recurringExpenses}
          onRowDelete={(row) => row.delete()}
          columns={[
            {
              label: 'Concepto',
              renderCell: (row: RecurringExpense) => (
                <TextField
                  type="text"
                  value={row.concept}
                  onChange={(e) => row.setConcept(e.target.value)}
                  placeholder="Concepto"
                />
              )
            },
            {
              label: 'Importe',
              renderCell: (row: RecurringExpense) => (
                <TextField
                  type="number"
                  value={row.cost}
                  onChange={(e) => row.setCost(parseFloat(e.target.value) || 0)}
                  placeholder="Importe"
                />
              )
            },
            {
              label: 'Frecuencia',
              renderCell: (row: RecurringExpense) => (
                <Select
                  value={row.periodicity}
                  onChange={(e) => row.setPeriodicity(e.target.value as any)}
                >
                  {Object.values(Periodicity).map((periodicity) => (
                    <MenuItem key={periodicity} value={periodicity}>
                      {periodicity}
                    </MenuItem>
                  ))}
                </Select>
              )
            },
            {
              label: 'Fecha inicio',
              renderCell: (row: RecurringExpense) => (
                <DatePicker
                  format="dd/MM/yyyy"
                  value={row.from ? new Date(row.from) : undefined}
                  slotProps={{ field: { clearable: true } }}
                  onChange={(date) => row.setFrom(date as Date | undefined)}
                />
              )
            },
            {
              label: 'Fecha fin',
              renderCell: (row: RecurringExpense) => (
                <DatePicker
                  format="dd/MM/yyyy"
                  value={row.to ? new Date(row.to) : null}
                  slotProps={{ field: { clearable: true } }}
                  onChange={(date) => row.setTo(date as Date | undefined)}
                />
              )
            },
            {
              label: 'Estimación',
              renderCell: (row: RecurringExpense) => (
                <Checkbox
                  checked={row.estimation}
                  onChange={(e) => row.setEstimation(e.target.checked)}
                />
              )
            },
            {
              label: 'Categoría',
              renderCell: (row: RecurringExpense) => (
                <Select
                  value={row.category.id}
                  onChange={(e) => {
                    const selectedCategory =
                      store.categories.find(
                        (cat) => cat.id === e.target.value
                      ) || row.category;

                    runInAction(() => {
                      row.setCategory(selectedCategory);
                    });
                  }}
                >
                  {store.categories.map((category: any) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              )
            },
            {
              label: 'Regex concepto',
              renderCell: (row: RecurringExpense) => (
                <TextField
                  type="text"
                  value={row.regexDeletion ?? ''}
                  onChange={(e) => row.setRegexDeletion(e.target.value)}
                  placeholder="Regex concepto"
                />
              )
            },
            {
              label: 'Gasto / Ingreso',
              renderCell: (row: RecurringExpense) => (
                <Select
                  value={row.isExpense.toString()}
                  onChange={(e) => row.setIsExpense(e.target.value === 'true')}
                >
                  <MenuItem value="true">Gasto</MenuItem>
                  <MenuItem value="false">Ingreso</MenuItem>
                </Select>
              )
            }
          ]}
        />
        <Box>
          <Alert severity="info">
            {anyNominaInRecurringExpenses
              ? 'Se ha encontrado al menos una nómina en los gastos recurrentes. Ten en cuenta que estas nóminas tienen prioridad sobre las nóminas de los gastos diarios o del sueldo bruto de la configuración de metas. Esto quiere decir que se descartarán las nóminas de dichas secciones, y únicamente se tendrán en cuenta las de los ingresos recurrentes, para calcular el ahorro mensual de las metas de ahorro.'
              : 'No hay ninguna nómina en los gastos recurrentes. Se recomienda añadir al menos una nómina para poder calcular el ahorro mensual de las metas de ahorro.'}
          </Alert>
          {store.isModified && (
            <Alert severity="info" sx={styles.alert}>
              Se han detectado cambios, por favor, guarda los cambios antes de
              salir de la página.
            </Alert>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleCreateDailyExpense()}
          startIcon={<AddCircleIcon />}
          sx={styles.buttonsMargin}
        >
          Añadir Fila
        </Button>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => store.updateRecurringExpenses()}
            startIcon={<SaveIcon />}
            sx={styles.buttonsMargin}
          >
            Guardar
          </Button>
        </Box>
      </Box>
      <ProfessionalTitle
        title="Alertas próximos pagos"
        showBorderTop={true}
        IconLeft={DateRageIcon}
      />
      <ProfessionalTitle
        title={`Mes actual (${currentMonthShort} ${currentYear})`}
        showBorderTop={true}
        IconLeft={TodayIcon}
        themeType={ThemeType.Secondary}
      />
      <Box sx={styles.container}>
        <ResponsiveTable
          isLoading={store.isLoading}
          data={store.filterThisMonthRecurringExpenses(1)}
          columns={[
            {
              label: 'Concepto',
              renderCell: (row: RecurringExpenseExtended) => <>{row.concept}</>
            },
            {
              label: 'Importe individual',
              renderCell: (row: RecurringExpenseExtended) => <>{row.cost}€</>
            },
            {
              label: 'Importe total',
              renderCell: (row: RecurringExpenseExtended) => <>{row.total}€</>
            },
            {
              label: 'Periodicidad',
              renderCell: (row: RecurringExpenseExtended) => (
                <>{row.periodicity}</>
              )
            }
          ]}
        />
      </Box>
      <ProfessionalTitle
        title={`Mes siguiente (${nextMonthShort} ${nextMonthYear})`}
        showBorderTop={true}
        IconLeft={TodayIcon}
        themeType={ThemeType.Secondary}
      />
      <Box sx={styles.container}>
        <ResponsiveTable
          isLoading={store.isLoading}
          data={store.filterThisMonthRecurringExpenses(2)}
          columns={[
            {
              label: 'Concepto',
              renderCell: (row: RecurringExpenseExtended) => <>{row.concept}</>
            },
            {
              label: 'Importe individual',
              renderCell: (row: RecurringExpenseExtended) => <>{row.cost}€</>
            },
            {
              label: 'Importe total',
              renderCell: (row: RecurringExpenseExtended) => <>{row.total}€</>
            },
            {
              label: 'Periodicidad',
              renderCell: (row: RecurringExpenseExtended) => (
                <>{row.periodicity}</>
              )
            }
          ]}
        />
      </Box>
    </Box>
  );
});

export default RecurringExpenses;
