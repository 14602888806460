import React from 'react';
import { MenuItem as MenuItemMui, SxProps, Theme } from '@mui/material';
import { useLocation } from 'react-router-dom';

const isRouteActive = (to, location) => {
  return location.pathname === to;
};

const MenuItem = ({
  children,
  to,
  component,
  sx,
  onClick,
  isHoverDefaultContrast = true
}: {
  children: React.ReactNode;
  to: string;
  component: React.ElementType;
  sx?: SxProps<Theme>;
  onClick?: () => void;
  isHoverDefaultContrast?: boolean;
}) => {
  const isActive = isRouteActive(to, useLocation());

  return (
    <MenuItemMui
      to={to}
      component={component}
      sx={{
        textDecoration: isActive ? 'underline' : 'none',
        backgroundColor: isActive ? 'background.paper' : 'transparent',
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: isHoverDefaultContrast
            ? 'background.default'
            : 'primary.dark',
          color: isHoverDefaultContrast ? 'text.primary' : 'background.default'
        },
        color: 'text.primary',
        ...sx
      }}
      onClick={onClick}
    >
      {children}
    </MenuItemMui>
  );
};

export default MenuItem;
