import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
  Tooltip
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Lightbulb as LightbulbIcon,
  ReportProblem as ReportProblemIcon,
  HelpOutline as HelpOutlineIcon
} from '@mui/icons-material';
import { observer } from 'mobx-react';
import shadows from '@mui/material/styles/shadows';
import theme from '../lib/theme';
import Progress from './Progress';

interface ColumnConfig<T> {
  tooltip?: string;
  label: string;
  renderCell: (row: T) => React.ReactNode;
}

interface ResponsiveTableProps<T> {
  data: T[];
  columns: ColumnConfig<T>[];
  onRowDelete?: (row: T) => void;
  isLoading?: boolean;
}

const NoData = ({
  onRowDelete,
  isLoading
}: {
  onRowDelete?: (row) => void;
  isLoading?: boolean;
}) => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      boxShadow: shadows[1],
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius
    },
    iconSize: {
      fontSize: 20
    },
    nuevaFilaContainer: {
      paddingTop: '20px'
    }
  };
  return (
    <Box sx={styles.container}>
      {isLoading ? (
        <Progress
          isVisible={isLoading}
          message="Cargando datos en tabla"
          fullScreen={false}
        />
      ) : (
        <>
          <Box>
            <ReportProblemIcon sx={styles.iconSize} />
            <Typography variant="body2">No hay datos disponibles.</Typography>
          </Box>
          {onRowDelete && (
            <Box sx={styles.nuevaFilaContainer}>
              <Typography variant="body2">
                <LightbulbIcon sx={styles.iconSize} />
                Puedes añadir una nueva fila haciendo click en el botón de
                añadir fila.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

// Reusable syntax based on https://mui.com/x/react-data-grid/column-definition/
const ResponsiveTable = <T extends Record<string, any>>({
  data,
  columns,
  onRowDelete,
  isLoading
}: ResponsiveTableProps<T>) => {
  // Avoid showing registers that are marked as deleted
  const dataFiltered = data.filter((row) => !row.toDelete);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const styles = {
    tableContainer: {
      width: '100%',
      overflow: 'auto'
    },
    tableCell: {
      fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
      paddingRight: { xs: '2px', sm: '4px', md: '6px', xl: '8px' },
      paddingLeft: { xs: '2px', sm: '4px', md: '6px', xl: '8px' }
    },
    buttonActionSmall: {
      width: '25px',
      height: '25px',
      minWidth: '25px'
    },
    deleteIcon: {
      fontSize: 16
    }
  };

  if (isMobile) {
    // Mobile version
    return (
      <Box display="flex" flexDirection="column" gap={2}>
        {dataFiltered.map((row, rowIndex) => (
          <Card key={row.id || rowIndex} variant="outlined">
            <CardContent>
              {columns.map((column, colIndex) => (
                <Box key={colIndex} mb={1}>
                  <Typography variant="body2">{column.label}</Typography>
                  <Box>{column.renderCell(row)}</Box>
                </Box>
              ))}
              {onRowDelete && (
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => onRowDelete(row)}
                >
                  Delete
                </Button>
              )}
            </CardContent>
          </Card>
        ))}
        {dataFiltered.length === 0 && (
          <NoData onRowDelete={onRowDelete} isLoading={isLoading} />
        )}
      </Box>
    );
  }

  // Desktop version
  return (
    <>
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index} align="left">
                  <Tooltip title={column.tooltip || ''} placement="top" arrow>
                    <Typography>
                      {column.label} {column.tooltip && <HelpOutlineIcon />}
                    </Typography>
                  </Tooltip>
                </TableCell>
              ))}
              {onRowDelete && <TableCell align="left">Acciones</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataFiltered.map((row, rowIndex) => (
              <TableRow key={row.id || rowIndex}>
                {columns.map((column, colIndex) => (
                  <TableCell key={colIndex} sx={styles.tableCell}>
                    {column.renderCell(row)}
                  </TableCell>
                ))}
                {onRowDelete && (
                  <TableCell sx={styles.tableCell}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => onRowDelete(row)}
                      sx={styles.buttonActionSmall}
                    >
                      <DeleteIcon sx={styles.deleteIcon} />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {dataFiltered.length === 0 && (
        <NoData onRowDelete={onRowDelete} isLoading={isLoading} />
      )}
    </>
  );
};

export default observer(ResponsiveTable);
