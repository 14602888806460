import { Box, List, ListItem, Typography } from '@mui/material';
import theme from '../../core/lib/theme';
import shadows from '@mui/material/styles/shadows';
import { CalculoIRPFType } from './SalaryCalculator';

const SalaryDetails = ({
  costesIRPF,
  community
}: {
  costesIRPF: CalculoIRPFType;
  community: string;
}) => {
  const styles = {
    container: {
      padding: `${theme.spacing(2)} 0`
    },
    tramosContenedor: {
      backgroundColor: '#f7fafc',
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      boxShadow: shadows[3],
      marginTop: theme.spacing(2)
    },
    tramo: {
      padding: theme.spacing(1),
      backgroundColor: '#edf2f7',
      borderRadius: theme.shape.borderRadius,
      marginBottom: theme.spacing(1)
    },
    listItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    listLabel: {
      lineHeight: 1.2
    }
  };

  return (
    <Box sx={styles.container}>
      {/* Generate a list with label and value for each of the following items of costesIRPF */}
      <Typography variant="h6">Detalle IRPF y SS</Typography>
      <List>
        <ListItem alignItems="flex-start" sx={styles.listItem}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={styles.listLabel}
          >
            Base cotización Seguridad Social
          </Typography>
          <Typography variant="body1">
            {costesIRPF.baseCotizacionSS.toFixed(2)}
          </Typography>
        </ListItem>
        <ListItem alignItems="flex-start" sx={styles.listItem}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={styles.listLabel}
          >
            Cuota mensual Seguridad Social
          </Typography>
          <Typography variant="body1">
            {costesIRPF.ssMensual.toFixed(2)}
          </Typography>
        </ListItem>
        <ListItem alignItems="flex-start" sx={styles.listItem}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={styles.listLabel}
          >
            Cuota anual Seguridad Social
          </Typography>
          <Typography variant="body1">
            {(costesIRPF.ssMensual * 12).toFixed(2)}
          </Typography>
        </ListItem>
        {community !== 'Estado' && (
          <>
            <ListItem alignItems="flex-start" sx={styles.listItem}>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={styles.listLabel}
              >
                IRPF estatal (Antes de deducciones:{' '}
                {costesIRPF.cuotaEstatalAntesDeducciones.toFixed(2)})
              </Typography>
              <Typography variant="body1">
                {costesIRPF.cuotaEstatal.toFixed(2)}
              </Typography>
            </ListItem>
            <ListItem alignItems="flex-start" sx={styles.listItem}>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={styles.listLabel}
              >
                IRPF Comunidad:(Antes de deducciones:{' '}
                {costesIRPF.cuotaComunidadAntesDeducciones.toFixed(2)})
              </Typography>
              <Typography variant="body1">
                {costesIRPF.cuotaComunidad.toFixed(2)}
              </Typography>
            </ListItem>
          </>
        )}
        <ListItem alignItems="flex-start" sx={styles.listItem}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={styles.listLabel}
          >
            Total IRPF (Antes de deducciones:{' '}
            {costesIRPF.cuotaTotalAntesDeducciones.toFixed(2)})
          </Typography>
          <Typography variant="body1">
            {costesIRPF.cuotaTotal.toFixed(2)}
          </Typography>
        </ListItem>
        <ListItem alignItems="flex-start" sx={styles.listItem}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={styles.listLabel}
          >
            Deducciones Comunidad (antes impuestos)
          </Typography>
          <Typography variant="body1">
            {costesIRPF.deduccionesComunidadAntesImpuestos.toFixed(2)}
          </Typography>
        </ListItem>
        <ListItem alignItems="flex-start" sx={styles.listItem}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={styles.listLabel}
          >
            Deducciones Estado (antes impuestos)
          </Typography>
          <Typography variant="body1">
            {costesIRPF.deduccionesEstadoAntesImpuestos.toFixed(2)}
          </Typography>
        </ListItem>
        {community !== 'Estado' && (
          <ListItem alignItems="flex-start" sx={styles.listItem}>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={styles.listLabel}
            >
              Deducciones Comunidad (después impuestos)
            </Typography>
            <Typography variant="body1">
              {costesIRPF.cuotaDeduccionesComunidad.toFixed(2)}
            </Typography>
          </ListItem>
        )}
        <ListItem alignItems="flex-start" sx={styles.listItem}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={styles.listLabel}
          >
            Deducciones Estado (después impuestos)
          </Typography>
          <Typography variant="body1">
            {(community !== 'Estado' &&
              costesIRPF.cuotaDeduccionesEstado.toFixed(2)) ||
              (costesIRPF.cuotaDeduccionesEstado * 2).toFixed(2)}
          </Typography>
        </ListItem>
        <ListItem alignItems="flex-start" sx={styles.listItem}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={styles.listLabel}
          >
            Base imponible (Base liquidable general)
          </Typography>
          <Typography variant="body1">{costesIRPF.baseImponible}</Typography>
        </ListItem>
        <ListItem alignItems="flex-start" sx={styles.listItem}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={styles.listLabel}
          >
            Base imponible con reducciones
          </Typography>
          <Typography variant="body1">
            {costesIRPF.baseImponibleConReducciones}
          </Typography>
        </ListItem>
      </List>

      {community !== 'Estado' && (
        <>
          <Typography variant="h6">Tramos IRPF Comunidad</Typography>
          <List>
            {costesIRPF.tramosComunidad.map((tramo) => (
              <ListItem alignItems="flex-start" sx={styles.listItem}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={styles.listLabel}
                >
                  Base liquidable
                </Typography>
                <Typography variant="body1">{tramo.baseLiquidable}</Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={styles.listLabel}
                >
                  Resto base liquidable
                </Typography>
                <Typography variant="body1">
                  {tramo.restoBaseLiquidable}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={styles.listLabel}
                >
                  Resto base liquidable actualizada
                </Typography>
                <Typography variant="body1">
                  {tramo.restoBaseLiquidableActualizada}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={styles.listLabel}
                >
                  Tipo porcentaje
                </Typography>
                <Typography variant="body1">{tramo.tipoPorcentaje}</Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={styles.listLabel}
                >
                  Impuesto sin mínimos
                </Typography>
                <Typography variant="body1">
                  {tramo.impuestoSinMinimos}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={styles.listLabel}
                >
                  Impuesto
                </Typography>
                <Typography variant="body1">
                  {tramo.impuesto.toFixed(2)}
                </Typography>
              </ListItem>
            ))}
          </List>
        </>
      )}
      <Typography variant="h6">Tramos IRPF Estatal</Typography>
      <List>
        {costesIRPF.tramosEstatal.map((tramo) => (
          <ListItem alignItems="flex-start" sx={styles.listItem}>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={styles.listLabel}
            >
              Base liquidable
            </Typography>
            <Typography variant="body1">{tramo.baseLiquidable}</Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={styles.listLabel}
            >
              Resto base liquidable
            </Typography>
            <Typography variant="body1">{tramo.restoBaseLiquidable}</Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={styles.listLabel}
            >
              Resto base liquidable actualizada
            </Typography>
            <Typography variant="body1">
              {tramo.restoBaseLiquidableActualizada}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={styles.listLabel}
            >
              Tipo porcentaje
            </Typography>
            <Typography variant="body1">
              {(community !== 'Estado' && tramo.tipoPorcentaje) ||
                tramo.tipoPorcentaje * 2}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={styles.listLabel}
            >
              Impuesto sin mínimos
            </Typography>
            <Typography variant="body1">
              {(community !== 'Estado' &&
                tramo.impuestoSinMinimos.toFixed(2)) ||
                (tramo.impuestoSinMinimos * 2).toFixed(2)}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={styles.listLabel}
            >
              Impuesto
            </Typography>
            <Typography variant="body1">
              {(community !== 'Estado' && tramo.impuesto.toFixed(2)) ||
                (tramo.impuesto * 2).toFixed(2)}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SalaryDetails;
