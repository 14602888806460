import { DailyExpense } from '../../personalExpenses/states/DailyExpensesStore';
import { RecurringExpense } from '../../personalExpenses/states/RecurringExpensesStore';
import {
  calcularIRPF,
  DatosFormulario
} from '../../salaryCalculator/components/SalaryCalculator';
import { Configuration } from '../states/ConfigurationStore';
import { PersonalGoal } from '../states/PersonalGoalsStore';
import { Periodicity } from '@prisma/client';

const yearAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
const endThisYear = new Date(new Date().getFullYear(), 11, 31);

type MonthlySavingsProps = {
  dailyExpenses: DailyExpense[];
  recurringExpenses: RecurringExpense[];
  useEstimatedRecurringExpenses?: boolean;
  useNonEstimatedRecurringExpenses?: boolean;
  dateStartRange?: Date;
  dateEndRange?: Date;
  configuration: Configuration;
};

export const netSalaryCalculator = ({ grossSalary }) => {
  const datosFormulario: DatosFormulario = {
    grossAnnual: grossSalary,
    year: new Date().getFullYear().toString(),
    community: 'Estado',
    jointTaxReturn: false,
    dataMinimums: {
      taxPayer: {
        age: 30
      },
      descendants: {
        menores25: 0,
        menores3: 0,
        death: 0
      },
      ascendants: {
        over65: 0,
        over75: 0,
        discapacitados: 0,
        death: 0
      },
      specialDisability: {
        '>33': 0,
        '>65': 0,
        necesitaAyudaTerceros: 0
      }
    }
  };

  const costesIRPF = calcularIRPF(datosFormulario);
  return costesIRPF.SueldoNetoMensual;
};

export const calculateGroupedGoalDate = ({
  personalGoals,
  dailyExpenses,
  recurringExpenses,
  configuration
}: {
  personalGoals: PersonalGoal[];
  dailyExpenses: DailyExpense[];
  recurringExpenses: RecurringExpense[];
  configuration: Configuration | undefined;
}): {
  goalDate: string | null;
  monthlySavings: number;
} => {
  let quantityGoal = 0;
  for (const element of personalGoals) {
    quantityGoal += element.quantity;
  }
  const monthlySavings = calculateMonthlySavings({
    dailyExpenses,
    recurringExpenses,
    useEstimatedRecurringExpenses: configuration?.useEstimatedRecurringExpenses,
    useNonEstimatedRecurringExpenses:
      configuration?.useNonEstimatedRecurringExpenses,
    dateStartRange: configuration?.dateStartRange ?? undefined,
    dateEndRange: configuration?.dateEndRange ?? undefined,
    configuration: configuration as Configuration
  });

  const goalDate = calculateSavingsGoalDate({
    monthlySavings,
    quantityGoal
  });
  return {
    goalDate: goalDate ? showDateFormat(goalDate) : null,
    monthlySavings
  };
};

enum NominaInUse {
  DAILY_EXPENSES = 'Gastos / Ingresos Diarios',
  RECURRING_EXPENSES = 'Gastos / Ingresos recurrentes',
  CONFIGURATION = 'Configuración'
}

export const whatNominaIsInUse = ({
  dailyExpenses,
  recurringExpenses,
  configuration
}): NominaInUse | null => {
  const dateStartRange = configuration?.dateStartRange ?? yearAgo;
  const dateEndRange = configuration?.dateEndRange ?? endThisYear;
  let what: NominaInUse | null = null;
  const dateRecurringCondition = (r) => {
    const fromCondition =
      !r?.from ||
      new Date(r.from).getTime() >= new Date(dateStartRange).getTime();
    const toCondition =
      !r?.to || new Date(r.to).getTime() <= new Date(dateEndRange).getTime();
    return fromCondition && toCondition;
  };

  const dateDailyCondition = (d) => {
    return (
      new Date(d.date).getTime() >= new Date(dateStartRange).getTime() &&
      new Date(d.date).getTime() <= new Date(dateEndRange).getTime()
    );
  };
  const anyNominaInDailyExpenses = dailyExpenses
    .filter((d) => !d.isExpense)
    .filter(dateDailyCondition)
    .some((d) => d.category.name === 'Nomina');
  const anyNominaInRecurringExpenses = recurringExpenses
    .filter((r) => !r.isExpense)
    // From and to are optional fields
    // If they are null or undefined, they won't be considered
    // Otherwise, they will be considered
    .filter(dateRecurringCondition)
    .some((r) => r.category.name === 'Nomina');
  const anyNominaInConfiguration = configuration?.grossSalary > 0;
  const useEstimatedRecurringExpenses =
    configuration?.useEstimatedRecurringExpenses;
  const useNonEstimatedRecurringExpenses =
    configuration?.useNonEstimatedRecurringExpenses;
  if (
    anyNominaInRecurringExpenses &&
    (useEstimatedRecurringExpenses || useNonEstimatedRecurringExpenses)
  ) {
    what = NominaInUse.RECURRING_EXPENSES;
  } else if (anyNominaInDailyExpenses) {
    what = NominaInUse.DAILY_EXPENSES;
  } else if (anyNominaInConfiguration) {
    what = NominaInUse.CONFIGURATION;
  }
  return what;
};

export const calculateMonthlySavings = ({
  dailyExpenses,
  recurringExpenses,
  useEstimatedRecurringExpenses = false,
  useNonEstimatedRecurringExpenses = false,
  dateStartRange = yearAgo,
  dateEndRange = endThisYear,
  configuration
}: MonthlySavingsProps) => {
  let netMonthlySalary = 0;
  let recurringExpensesCost = 0;
  let recurringExpensesIncome = 0;

  const dateRecurringCondition = (r) => {
    const fromCondition =
      !r?.from ||
      new Date(r.from).getTime() >= new Date(dateStartRange).getTime();
    const toCondition =
      !r?.to || new Date(r.to).getTime() <= new Date(dateEndRange).getTime();
    return fromCondition && toCondition;
  };

  const dateDailyCondition = (d) => {
    return (
      new Date(d.date).getTime() >= new Date(dateStartRange).getTime() &&
      new Date(d.date).getTime() <= new Date(dateEndRange).getTime()
    );
  };

  const calculateCostByPeriodicity = (expense) => {
    switch (expense.periodicity) {
      case Periodicity.ANUAL:
        return expense.cost;
      case Periodicity.MENSUAL:
        return expense.cost * 12;
      case Periodicity.SEMESTRAL:
        return expense.cost * 2;
      case Periodicity.TRIMESTRAL:
        return expense.cost * 4;
      case Periodicity.QUINCENAL:
        return expense.cost * 24;
      case Periodicity.SEMANAL:
        return expense.cost * 48;
      case Periodicity.DIARIO:
        return expense.cost * 360;
      default:
        return expense.cost;
    }
  };

  let dailyExpensesCost = dailyExpenses
    .filter((d) => d.isExpense)
    .filter(dateDailyCondition)
    .reduce((acc, expense) => acc + expense.cost, 0);
  let dailyExpensesIncomeWithoutNomina = dailyExpenses
    .filter((d) => !d.isExpense)
    .filter((d) => d.category.name !== 'Nomina')
    .filter(dateDailyCondition)
    .reduce((acc, expense) => acc + expense.cost, 0);
  let dailyExpensesIncomeWithNomina = dailyExpenses
    .filter((d) => !d.isExpense)
    .filter((d) => d.category.name === 'Nomina')
    .filter(dateDailyCondition)
    .reduce((acc, expense) => acc + expense.cost, 0);
  // Any daily expense set as income and categorised as "Nomina"
  const anyNominaInDailyExpenses = dailyExpenses
    .filter((d) => !d.isExpense)
    .filter(dateDailyCondition)
    .some((d) => d.category.name === 'Nomina');
  const anyNominaInRecurringExpenses = recurringExpenses
    .filter((r) => !r.isExpense)
    // From and to are optional fields
    // If they are null or undefined, they won't be considered
    // Otherwise, they will be considered
    .filter(dateRecurringCondition)
    .some((r) => r.category.name === 'Nomina');
  const anyNominaInConfiguration = configuration?.grossSalary > 0;
  // Calculate yearly savings
  let yearlySavings = dailyExpensesIncomeWithoutNomina - dailyExpensesCost;
  if (useEstimatedRecurringExpenses) {
    // Estimated recurring expenses
    recurringExpensesCost += recurringExpenses
      .filter((r) => r.isExpense)
      .filter((r) => r.estimation)
      .filter(dateRecurringCondition)
      .reduce((acc, expense) => acc + calculateCostByPeriodicity(expense), 0);
    // Estimated recurring income
    recurringExpensesIncome += recurringExpenses
      .filter((r) => !r.isExpense)
      .filter((r) => r.estimation)
      .filter(dateRecurringCondition)
      .reduce((acc, expense) => acc + calculateCostByPeriodicity(expense), 0);
    // Add yearly savings from recurring expenses
    yearlySavings += recurringExpensesIncome - recurringExpensesCost;
  }
  if (useNonEstimatedRecurringExpenses) {
    // Non estimated recurring expenses
    recurringExpensesCost += recurringExpenses
      .filter((r) => r.isExpense)
      .filter((r) => !r.estimation)
      .filter(dateRecurringCondition)
      .reduce((acc, expense) => acc + calculateCostByPeriodicity(expense), 0);

    // Non estimated recurring income
    recurringExpensesIncome += recurringExpenses
      .filter((r) => !r.isExpense)
      .filter((r) => !r.estimation)
      .filter(dateRecurringCondition)
      .reduce((acc, expense) => acc + calculateCostByPeriodicity(expense), 0);
    // Add yearly savings from recurring expenses
    yearlySavings += recurringExpensesIncome - recurringExpensesCost;
  }

  // If there isn't any nomina in
  // recurring expenses / income OR
  // in configuration, then
  // Use nomina from daily expenses / income
  if (
    !anyNominaInConfiguration &&
    (!anyNominaInRecurringExpenses ||
      (!useEstimatedRecurringExpenses && !useNonEstimatedRecurringExpenses)) &&
    dailyExpensesIncomeWithNomina
  ) {
    yearlySavings += dailyExpensesIncomeWithNomina;
  }
  const monthsInDateRange =
    (new Date(dateEndRange).getFullYear() -
      new Date(dateStartRange).getFullYear()) *
      12 +
    new Date(dateEndRange).getMonth() -
    new Date(dateStartRange).getMonth();

  let monthlySavings = 0;
  if (yearlySavings !== 0) {
    monthlySavings = yearlySavings / monthsInDateRange;
  }

  // If there is no income either on daily expenses or recurring expenses,
  // or there is income on recurring expenses, but user chose to not use it,
  // then the system will calculate the net salary and use it as income.
  if (
    !anyNominaInDailyExpenses &&
    (!anyNominaInRecurringExpenses ||
      (!useEstimatedRecurringExpenses && !useNonEstimatedRecurringExpenses)) &&
    anyNominaInConfiguration
  ) {
    netMonthlySalary = netSalaryCalculator({
      grossSalary: configuration?.grossSalary
    });
    monthlySavings += netMonthlySalary;
  }
  return monthlySavings;
};

export const calculateSavingsGoalDate = ({
  monthlySavings,
  quantityGoal
}: {
  monthlySavings: number;
  quantityGoal: number;
}): Date | null => {
  // If there is any savings, the goal date will be today
  if (monthlySavings <= 0) {
    return null;
  }
  const months = quantityGoal / monthlySavings;
  const fullMonths = Math.floor(months);
  const fractionalMonths = months - fullMonths;
  const today = new Date();

  // Add full months to today
  today.setMonth(today.getMonth() + fullMonths);
  // Calculate the number of days
  const daysToAdd = Math.round(fractionalMonths * 30);
  // Add days to today
  today.setDate(today.getDate() + daysToAdd);
  const goalDate = new Date(today);

  return goalDate;
};

export const showDateFormat = (date: Date | null) => {
  if (!date) {
    return '';
  }
  return date.toLocaleDateString('es-ES', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
};
