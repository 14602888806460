import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction
} from 'mobx';
import { v4 as uuidv4 } from 'uuid';

export class PersonalGoalsStore {
  transportLayer;
  isLoading;
  isSaving;
  isModified;
  personalGoals: PersonalGoal[] = [];
  constructor(transportLayer) {
    makeAutoObservable(this, {
      personalGoals: true,
      isLoading: observable,
      isSaving: observable,
      isModified: observable,
      loadPersonalGoals: action,
      createPersonalGoal: action,
      updatePersonalGoals: action
    });
    this.isLoading = false;
    this.isSaving = false;
    this.isModified = false;
    this.transportLayer = transportLayer;
    this.personalGoals = [];
  }

  async loadPersonalGoals() {
    this.isLoading = true;
    const response = await this.transportLayer.getPersonalGoals();
    runInAction(() => {
      response?.forEach((goal) => this.updatePersonalGoalsFromServer(goal));
      this.isLoading = false;
      this.isModified = false;
    });
  }

  updatePersonalGoalsFromServer(json) {
    let personalGoal = this.personalGoals.find((goal) => goal.id === json.id);
    if (!personalGoal) {
      personalGoal = new PersonalGoal(this, json.id);
      this.personalGoals.push(personalGoal);
    }
    personalGoal.updateFromJson(json);
  }

  async createPersonalGoal() {
    const personalGoal = new PersonalGoal(this, uuidv4());
    personalGoal.concept = 'Nombre';
    personalGoal.quantity = 0;
    this.personalGoals.push(personalGoal);
    this.isModified = true;
    return personalGoal;
  }

  async updatePersonalGoals() {
    this.isSaving = true;
    await this.transportLayer.savePersonalGoals(
      this.personalGoals.map((goal) => goal.toJson)
    );
    this.isSaving = false;
    this.isModified = false;
  }

  get toJsonCSV() {
    return this.personalGoals
      .filter((goal) => !goal.toDelete)
      .map((goal) => goal.toJsonCSV);
  }
}

export class PersonalGoal {
  store: PersonalGoalsStore;
  id: string;
  userId: number = -1;
  concept: string = '';
  quantity: number = 0;
  isNew: boolean = true;
  toDelete: boolean = false;

  constructor(store: PersonalGoalsStore, id = '') {
    makeAutoObservable(this, {
      store: true,
      updateFromJson: action,
      delete: action,
      toJson: computed,
      id: observable,
      userId: observable,
      concept: observable,
      quantity: observable,
      isNew: observable,
      toDelete: observable
    });
    this.store = store;
    this.id = id;
  }

  setConcept(concept) {
    this.concept = concept;
    this.store.isModified = true;
  }

  setQuantity(quantity) {
    this.quantity = quantity;
    this.store.isModified = true;
  }

  updateFromJson(json) {
    this.id = json.id;
    this.userId = json.userId;
    this.concept = json.concept;
    this.quantity = json.quantity;
    this.isNew = false;
    this.toDelete = false;
  }

  delete() {
    this.store.isModified = true;
    this.toDelete = true;
  }

  get toJson() {
    return {
      id: this.id,
      userId: this.userId,
      concept: this.concept,
      quantity: this.quantity,
      isNew: this.isNew,
      toDelete: this.toDelete
    };
  }

  get toJsonCSV() {
    return {
      concept: this.concept,
      quantity: this.quantity
    };
  }
}
