import { observer } from 'mobx-react';
import { RootStoreContext } from '../../core/states/RootStore';
import { useContext } from 'react';
import React from 'react';
import { PersonalGoal } from '../states/PersonalGoalsStore';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  TextField,
  Typography
} from '@mui/material';
import ProfessionalTitle from '../../core/components/ProfessionalTitle';
import theme from '../../core/lib/theme';
import ResponsiveTable from '../../core/components/ResponsiveTable';
import {
  AddCircle as AddCircleIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { Configuration } from '../states/ConfigurationStore';
import { RecurringExpense } from '../../personalExpenses/states/RecurringExpensesStore';
import { DailyExpense } from '../../personalExpenses/states/DailyExpensesStore';
import { DatePicker } from '@mui/x-date-pickers';
import { exportToExcell } from '../../core/lib/export';
import shadows from '@mui/material/styles/shadows';
import {
  calculateGroupedGoalDate,
  calculateMonthlySavings,
  calculateSavingsGoalDate,
  netSalaryCalculator,
  showDateFormat,
  whatNominaIsInUse
} from '../lib/helpers';

const PersonalGoals = observer(() => {
  const storeContext = useContext(RootStoreContext);
  const store = storeContext.personalGoalsStore;
  const [personalGoals, setPersonalGoals] = React.useState<PersonalGoal[]>([]);
  const configurationStore = storeContext.configurationStore;
  const [configuration, setConfiguration] = React.useState<Configuration>();
  const [dailyExpenses, setDailyExpenses] = React.useState<DailyExpense[]>([]);
  const [recurringExpenses, setRecurringExpenses] = React.useState<
    RecurringExpense[]
  >([]);
  const [snackOpen, setSnackOpen] = React.useState(false);

  React.useEffect(() => {
    const loadGoals = async () => {
      await store.loadPersonalGoals();
      setPersonalGoals(store.personalGoals);
    };
    loadGoals();
    const loadConfiguration = async () => {
      await configurationStore.loadConfiguration();
      setConfiguration(configurationStore.configuration ?? undefined);
    };
    loadConfiguration();
    const loadDailyExpenses = async () => {
      const dailyExpensesStore = storeContext.dailyExpensesStore;
      if (dailyExpensesStore) {
        await dailyExpensesStore.loadDailyExpenses();
        setDailyExpenses(dailyExpensesStore.dailyExpenses);
      }
    };
    loadDailyExpenses();
    const loadRecurringExpenses = async () => {
      const recurringExpensesStore = storeContext.recurringExpensesStore;
      if (recurringExpensesStore) {
        await recurringExpensesStore.loadRecurringExpenses();
        setRecurringExpenses(recurringExpensesStore.recurringExpenses);
      }
    };
    loadRecurringExpenses();
  }, []);

  const styles = {
    container: {
      margin: theme.spacing(2)
    },
    buttonsMargin: {
      margin: `${theme.spacing(1)} 0`
    },
    formGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2)
    },
    formLabel: {
      fontWeight: 'bold',
      color: '#4a5568',
      marginRight: theme.spacing(2),
      flex: '1'
    },
    formInput: {
      width: '60%',
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      border: '1px solid #cbd5e0',
      borderRadius: theme.shape.borderRadius,
      fontSize: '16px',
      color: '#4a5568'
    },
    exportContainer: {
      // Align to the right
      display: 'flex',
      justifyContent: 'flex-end'
    },
    groupGoalPredictions: {
      maxWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      boxShadow: shadows[1],
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius
    },
    alert: {
      margin: `${theme.spacing(1)} 0`
    }
  };

  const groupedData = calculateGroupedGoalDate({
    personalGoals,
    dailyExpenses,
    recurringExpenses,
    configuration
  });

  const nominaInUse = whatNominaIsInUse({
    dailyExpenses,
    recurringExpenses,
    configuration
  });

  const tooltipSalaryMessage =
    'Para calcular el ahorro mensual, el sistema busca si hay movimientos diarios o recurrentes categorizados como nómina. Si no los hay, por favor, introduce tu salario bruto, y el sistema utilizará el cálculo resultante del salario neto para calcular el ahorro mensual. De todas formas, se recomienda que se introduzca el salario neto en los gastos e ingresos recurrentes (Si aún no lo conoces, puedes calcularlo con nuestra calculadora de sueldo neto). Esto es así, porque el sistema no sabe en qué comunidad te encuentras, y calcula el valor neto sin tener en cuenta tu comunidad autónoma. (La diferencia puede ser desde unos pocos euros hasta cientos de euros en los salarios más altos)';

  return (
    <Box>
      <ProfessionalTitle title="Metas personales" />
      <Box sx={styles.container}>
        {personalGoals.length !== 0 && (
          <Box sx={styles.exportContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                exportToExcell({
                  data: store.toJsonCSV,
                  fileName: 'MetasPersonales.xls'
                });
              }}
              startIcon={<AddCircleIcon />}
              sx={styles.buttonsMargin}
            >
              Exportar
            </Button>
          </Box>
        )}

        <ResponsiveTable
          isLoading={store.isLoading}
          data={personalGoals}
          onRowDelete={(row) => row.delete()}
          columns={[
            {
              label: 'Concepto',
              renderCell: (row: PersonalGoal) => (
                <TextField
                  value={row.concept}
                  onChange={(e) => row.setConcept(e.target.value)}
                  placeholder="Concepto"
                />
              )
            },
            {
              label: 'Importe',
              renderCell: (row: PersonalGoal) => (
                <TextField
                  type="number"
                  value={row.quantity}
                  onChange={(e) => row.setQuantity(parseFloat(e.target.value))}
                  placeholder="Importe"
                />
              )
            },
            {
              label: 'Fecha predicción',
              renderCell: (row: PersonalGoal) => (
                <Typography>
                  {showDateFormat(
                    calculateSavingsGoalDate({
                      monthlySavings: calculateMonthlySavings({
                        dailyExpenses,
                        recurringExpenses,
                        useEstimatedRecurringExpenses:
                          configuration?.useEstimatedRecurringExpenses,
                        useNonEstimatedRecurringExpenses:
                          configuration?.useNonEstimatedRecurringExpenses,
                        dateStartRange:
                          configuration?.dateStartRange ?? undefined,
                        dateEndRange: configuration?.dateEndRange ?? undefined,
                        configuration: configuration as Configuration
                      }),
                      quantityGoal: row.quantity
                    })
                  )}
                </Typography>
              )
            }
          ]}
        />
        {groupedData.goalDate && (
          <Box sx={styles.groupGoalPredictions}>
            <Typography variant="h6">Fecha predicción conjunta</Typography>
            <Typography variant="h5">{groupedData.goalDate}</Typography>
          </Box>
        )}
        {groupedData.monthlySavings <= 0 && (
          <Alert severity="warning" sx={styles.alert}>
            No se pueden calcular las metas de ahorro al tener unos ingresos
            mensuales negativos de {groupedData.monthlySavings.toFixed(2)}€.
          </Alert>
        )}
        <Box>
          {store.isModified && (
            <Alert severity="info" sx={styles.alert}>
              Se han detectado cambios, por favor, guarda los cambios antes de
              salir de la página.
            </Alert>
          )}
          {nominaInUse && (
            <Alert severity="info" sx={styles.alert}>
              Para calcular el ahorro mensual, se está utilizando la nómina
              proveniente de {nominaInUse}.
            </Alert>
          )}
          {groupedData.monthlySavings > 0 && (
            <Alert severity="info" sx={styles.alert}>
              Tienes unos ahorros mensuales positivos de{' '}
              {groupedData.monthlySavings.toFixed(2)}€.
            </Alert>
          )}
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            store.createPersonalGoal();
          }}
          startIcon={<AddCircleIcon />}
          sx={styles.buttonsMargin}
        >
          Añadir meta
        </Button>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              store.updatePersonalGoals();
            }}
            startIcon={<SaveIcon />}
            sx={styles.buttonsMargin}
          >
            Guardar
          </Button>
        </Box>
      </Box>
      <ProfessionalTitle title="Configuración" showBorderTop={true} />
      <Box sx={styles.container}>
        <ResponsiveTable
          isLoading={store.isLoading}
          data={configuration ? [configuration] : []}
          columns={[
            {
              label: 'Fecha inicio',
              renderCell: (row: Configuration) => (
                <DatePicker
                  format="dd/MM/yyyy"
                  value={
                    row.dateStartRange ? new Date(row.dateStartRange) : null
                  }
                  slotProps={{ field: { clearable: true } }}
                  onChange={(date) =>
                    row.setDateStartRange(date as Date | undefined)
                  }
                />
              )
            },
            {
              label: 'Fecha fin',
              renderCell: (row: Configuration) => (
                <DatePicker
                  format="dd/MM/yyyy"
                  value={row.dateEndRange ? new Date(row.dateEndRange) : null}
                  slotProps={{ field: { clearable: true } }}
                  onChange={(date) =>
                    row.setDateEndRange(date as Date | undefined)
                  }
                />
              )
            },
            {
              label: 'Incluir gastos recurrentes estimados',
              renderCell: (row: Configuration) => (
                <Checkbox
                  checked={row.useEstimatedRecurringExpenses}
                  onChange={(e) =>
                    row.setUseEstimatedRecurringExpenses(e.target.checked)
                  }
                />
              )
            },
            {
              label: 'Incluir gastos recurrentes no estimados',
              renderCell: (row: Configuration) => (
                <Checkbox
                  checked={row.useNonEstimatedRecurringExpenses}
                  onChange={(e) =>
                    row.setUseNonEstimatedRecurringExpenses(e.target.checked)
                  }
                />
              )
            },
            {
              label: 'Ingresos brutos anuales',
              tooltip: tooltipSalaryMessage,
              renderCell: (row: Configuration) => (
                <TextField
                  type="number"
                  value={row.grossSalary}
                  onChange={(e) =>
                    row.setGrossSalary(parseFloat(e.target.value) || 0)
                  }
                  placeholder="Ingresos brutos anuales"
                />
              )
            },
            {
              label: 'Ingresos netos mensuales',
              tooltip: tooltipSalaryMessage,
              renderCell: (row: Configuration) => (
                <Typography>
                  {netSalaryCalculator({ grossSalary: row.grossSalary })}
                </Typography>
              )
            }
          ]}
        />
        <Box>
          {configurationStore.isModified && (
            <Alert severity="info" sx={styles.alert}>
              Se han detectado cambios, por favor, guarda los cambios antes de
              salir de la página.
            </Alert>
          )}
        </Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            configurationStore.updateConfiguration();
          }}
          startIcon={<SaveIcon />}
          sx={styles.buttonsMargin}
        >
          Guardar
        </Button>
      </Box>
    </Box>
  );
});

export default PersonalGoals;
